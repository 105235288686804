/*eslint-disable*/
import React,{useState,useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import {sociallinksfunction} from '../../actions/v1/report';
import { Link,useLocation } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'

const useStyles = makeStyles(styles);

export default function Footer(props) {
 
  const classes           =  useStyles();
  const Wallet_Details    =  useSelector(state => state.wallet_connect_context);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const[sociallinks,setsociallinks]=useState([])
  const[Footer_Link,Set_Footer_link]=useState([])
  
  
  useEffect(()=>{
    sociallinksfunct()
    },[])
  
  const sociallinksfunct=async()=>{
    var soci= await sociallinksfunction()
    if(soci&&soci.data&&soci.data.soci&&soci.data.soci[0]&&soci.data.soci[0].social.length>0){
      setsociallinks(soci.data.soci[0].social)
    }
    else{

      setsociallinks([])
    }
  }
 
  const toggleUsermenu = () => {
    var useclass = document.getElementsByClassName("usemneu_dd");
    for(var i=0;i<useclass.length;i++)
    {
      useclass[i].classList.toggle('d-none')
    } 
  }
  
 
  var location=useLocation();
  return (
    <div>
   
    <footer className={footerClasses}>
    <div className="container">
    <div className="row pb-4">
        <div className="col-12 col-md-12 col-lg-3 col-xl-3 mt-3 mt-lg-0 left-footer-sec">
            <a href="/">
                <span className="img-fluid logo_przn" alt="Shape"></span>
            </a>
            <p className="footer_big_text">The best & exclusive E-NFT economy</p>
        </div>
        <div className="col-12 col-md-12 col-lg-9 col-xl-9 mt-3 mt-lg-0">
            <div className="row footer_row_new">
                <div className="col-12 col-md-4 col-lg-4 col-xl-3 mt-3 mt-lg-4">
                    <p className="footer_heade">Bid Pixels</p>
                    <ul className="footer_ul">
                      {Wallet_Details.UserAccountAddr==""?
                        <li>
                            <Link to="#" data-toggle="modal" data-target="#connect_modal">Connect wallet</Link>
                        </li>
                        :
                         <li>
                         <Link to="#" onClick={()=>toggleUsermenu()} >Connect wallet</Link>
                     </li>}
                        <li>
                            <Link to="/create">Create</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xl-3 mt-3 mt-lg-4">
                    <p className="footer_heade">BPX Token</p>
                    <ul className="footer_ul">
                     
                        <li>
                            <a href="https://token.bidpixels.com/" target="_blank">Buy Token</a>
                        </li>
                        <li>
                            <Link to="/token-BPX-BSC-0x8cb6aa6e8575d87961bd01d2ff09007c2499ec56">Token Info</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xl-3 mt-3 mt-lg-4">
                    <p className="footer_heade">Info</p>
                    <ul className="footer_ul">
                        <li><Link to="/aboutus">About Us</Link></li>
                        <li><Link to="/how-it-works">How It Works</Link></li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xl-3 mt-3 mt-lg-4">
                    <p className="footer_heade">Profile</p>
                    <ul className="footer_ul">
                        <li><Link to="/My-items">My-Items</Link></li>
                        <li><Link to="/edit-profile">Edit-Profile</Link></li>
                    </ul>
                </div>

                <div className="col-12 col-md-4 col-lg-4 col-xl-3 mt-3 mt-lg-4">
                    <p className="footer_heade">Social</p>
                    <ul className="footer_ul">
                    {/* <Link to="#" target="_blank">Twiiter</Link>     
                    <Link to="#" target="_blank">Instagram</Link>    
                  
                    <Link to="#" target="_blank">Facebook</Link>         */}
                    {sociallinks && sociallinks.length != 0 && sociallinks.map((list) => {
                        return(
                                
                            <li key={Math.random()}>
                            <a href={Object.values(list).toString()} target="_blank">{Object.keys(list)}</a>           
                            </li> 
                       )
                        })
                        }   
                        </ul>
                        {/* </div> */}
                  </div>
                
                     
                    </div>
                </div>
               
             
           
                </div>
               
            </div>
    </footer>
    <hr className="hr_hrey my-0"></hr>
                <footer className="jss58 py-0 mt-0 footer_bottom">
                  <div className="container">
                    <div className="footerbottom py-4 justify-content-center">
                    
                        <p className="copyright_txt mb-md-0 text-center">
                          <span className="mb-0 text-white font_w_600 font_14">Copyright © BidPixels | All Rights Reserved</span></p>
                        {/* <p className="copyright_txt mb-md-0">We use cookies for better service.Accept</p> */}
                    
                        </div>
                        </div>
                        </footer>
</div>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
