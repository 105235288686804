import React from 'react';
import config from '../../lib/config'
const ImgVidAud =   (props) =>{
    var {
        file,
        thumb,
        vidAud,data,
        type,
        class_name
    } = props
    //console.log("props",props)
    // return true
   const onErrSong   =   (e) =>{
        e.target.src = config.Lod
    }
return(
   !type ? <></> :
    (type.includes('.mp4') ?
    ((data   === "info" )  ?
      <video
            id="my-video"
            className={class_name}
            autoPlay 
            playsInline 
            loop
            muted 
            controls
            preload="auto"
            poster={thumb}
            alt="video" loading="lazy"
        >
            <source
                src={vidAud}
                type="video/mp4" />
        </video>
        :
        <img src={thumb} className =   {class_name} onError={onErrSong} loading="lazy" /> 
        )
            :
            type.includes('.mp3') ?
            <>
			<img src={thumb} className = {class_name} onError={onErrSong}  loading="lazy"/>
            {(data   === "info") ?
            <audio  controlsList="nodownload"
                alt='audio'
                playsInline loop  
                type="audio/*"
                autostart="off"
                src={vidAud}
                controls
                >
            </audio>
            :<audio  controlsList="nodownload"
            alt='audio'
            playsInline loop  muted
            type="audio/*"
            autostart="off"
            src={vidAud}
            >
        </audio>}

            </>
            :
            <img loading="lazy"
            src={file   ?   file    :   vidAud}
            alt="Collections" className =   {class_name} />)
)
}

export default ImgVidAud