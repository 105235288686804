
/**
 * FILE		   	:	activity
 * DISPATCH		:	NiL
 * REF			  :	Nil
 * METHOD   	: ActivityCall,
 * C-DATE   	: 30_01_22
 * S-DATE   	: 30-01-22
*/


import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import ActivityCard from './separate/activityTab'
import {ActivityCall} from 'actions/v1/report'
import config from 'lib/config'
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {
  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  const classes = useStyles();
  const { ...rest } = props;
  
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [CatName, setCatName] = useState('All');
  

    


  useEffect(()=>{
    TokenListCall();
  },[])
 
  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);

    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1
    });
  }
  async function TokenListCall(data = {}) {
     var name = CatName;
    if (data.CatName) {
      name = data.CatName
    }
  
    var reqdata={
      currAddr:Wallet_Details.UserAccountAddr,
      limit:9,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
      tabName: name,
    }
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);
    CatBasedTokenList.loader = false;
    var activitys=await ActivityCall(reqdata)

    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if(activitys&&activitys.data&&activitys.data.list&&(activitys.data.list).length>0){
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].onmore = false;
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(activitys.data.list);
      setCatBasedTokenList([]);
      CatBasedTokenList[name].onmore = true;
      setCatBasedTokenList(CatBasedTokenList); 
     }
    else{
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    
   
  }
  async function catChange(name) {
    if (name != CatName) {
      setCatName(name);
     
    }
  }
useEffect(()=>{
  if (typeof CatBasedTokenList[CatName] == 'undefined') {
    CatBasedTokenList[CatName] = { page: 1, list: [], onmore: true };
    setCatBasedTokenList(CatBasedTokenList);
    TokenListCall({ CatName: CatName, page: 1 });
  }
  else if (CatBasedTokenList[CatName].page !== 1) {
    TokenListCall({ CatName: CatName, page: 1 });
  }
},[CatName])

  return (
    <div className="inner_header">
     
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1">
        <div className="container">
          
            
              <div className="d-flex justify-content-between">
                <h2 className="inner_title">Activity</h2>
              
              </div>
        </div>
        </div>
        <div className="container mt-3">
        <GridContainer>
  <GridItem xs={12} sm={12} md={12}>                                       
    <nav className="masonry_tab_nav mt-4 items_tab_outer">
      <div className="nav nav-tabs masonry_tab primary_tab items_tab  items_tab_new pb-2 pl-2" id="nav-tab" role="tablist">
        <a className="nav-link active" id="all-tab" onClick={() => catChange('All')} data-toggle="tab" data-tabname="all" href="#all" role="tab" aria-controls="all" aria-selected="true">
         <span className="tab_head p-2">All</span>
          </a>                 
       
          <a className="nav-link" id="all-tab" onClick={() => catChange('mine')} data-toggle="tab" data-tabname="all" href="#all" role="tab" aria-controls="all" aria-selected="false">
          <span className="tab_head p-2"> My Activity</span>
          </a>
      </div>
    </nav>
    <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
      <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
         <div className="proposal_panel_overall">   
                      
 
         {(CatBasedTokenList && CatName && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list && CatBasedTokenList[CatName].list.length > 0) ? 
         
             <div className="followers_overall py-3"  key={Math.random()}>
              <div className="row">
              {(CatBasedTokenList[CatName].list.map((item) => {
              return <ActivityCard 
             key={Math.random()}
              item={item}
              data=""
              type="activity"
              myadd={Wallet_Details.UserAccountAddr?Wallet_Details.UserAccountAddr:''}/>
              
                 
                }))}
                 </div></div>
              
         :
         <div className="text-center py-5">
         <p className="not_found_text">No items found</p>
         <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p></div>
        }
        
      
        {
              (CatBasedTokenList 
                && CatBasedTokenList.loader == false 
                && CatBasedTokenList[CatName] 
                && CatBasedTokenList[CatName].onmore == true) ? (
                <div className="text-center mt-4 w-100 ">
                  {(CatBasedTokenList.loader == true) ? <i className="fa fa-spinner spinner_icon spinner_red" aria-hidden="true" id="spin"></i> : ('')}
                  <div id="spinBut">
                    <Button className="create_btn create_newbtnload mt-3 mx-auto btn-block" id="onmore" onClick={onLoadMore} style={{ display: "flex" }}>
                      Load More
                    </Button>
                  </div>
                </div>) : ('')}
      </div>
        </div>
      </div>     
             
    
  </GridItem>
</GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}







