import React, {
    forwardRef,
    useImperativeHandle
} from 'react';

import { Button } from '@material-ui/core';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import isEmpty from 'lib/isEmpty';
import { useLocation, useHistory, Link } from 'react-router-dom'
import config from '../../lib/config';
import SINGLE from '../../ABI/SINGLE.json'
import MULTIPLE from '../../ABI/MULTIPLE.json'
import Convert from '../separate/Convert'
import {
    TokenPriceChange_update_Action,
    checkOtherPlatformDetais1155,
    token_usd
} from '../../actions/v1/token';
import { getReceipt } from 'actions/v1/getReceiptFunc';
import Trade from '../../ABI/Trade.json'
import Convert1 from '../../views/separate/Convert1'
import { useSelector } from 'react-redux'

import {toast} from 'react-toastify';
import useContractProviderHook from 'actions/web3/contract_provider';
import ImgVidAud from './imgVidAud';
let toasterOption = config.toasterOption;




export const CancelOrderRef = forwardRef((props, ref) => {
    const Wallet_Details = useSelector(state => state.wallet_connect_context);
    const history = useHistory();
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('start');
    const [TokenPrice, Set_TokenPrice] = React.useState(0);
    const [Owners, Set_Owners] = React.useState({});
    const [Item_Owner, Set_Item_Owner] = React.useState({});
    const [Items, Set_Item] = React.useState({});
    const location = useLocation();
    const my_hook   =   useContractProviderHook()
    async function FormSubmit() {
        Set_FormSubmitLoading('processing');
        var receipt  =   await   my_hook.cancel_order_721_1155(Items.type,Items.contractAddress,Items.tokenCounts,Wallet_Details.Accounts)
       if(receipt){
        var postData = {
            tokenOwner: Wallet_Details.UserAccountAddr,
            tokenCounts: Items.tokenCounts,
            tokenPrice: 0,
            blockHash: receipt.HashValue,
            transactionHash: receipt.HashValue,
            CoinName: '',
            PutOnSaleType: 'cancel',
            type: Item_Owner.type,
            contractAddress: Items.contractAddress
        }
        var Resp = await TokenPriceChange_update_Action(postData)
        if (Resp && Resp.data && Resp.data && Resp.data.message == 'success') {
            Set_FormSubmitLoading('done');
            toast.success(config.SuccessTransaction, toasterOption)
            window.$('.modal').modal('hide');
            setTimeout(() => {
                if (location.pathname.includes('/info')) {
                    props.Refresh_page()
                }
                else {
                    history.push('/info/' + Wallet_Details.UserAccountAddr + '/' + Item_Owner.contractAddress + '/' + Item_Owner.tokenCounts)
                }
            }, 2000);
        }
       }
       else{
        Set_FormSubmitLoading('try');
        toast.error(config.ErrorTransaction, toasterOption)
       }
     

    }



    useImperativeHandle(
        ref,
        () => ({
            async CancelOrder_Click(item, Owner, itemOwner) {
                /**
                 * item- initial item
                 * 
                 * owner - owner detail user
                 * 
                 * item owner- itemowner db
                 */
                if (Wallet_Details.UserAccountAddr != "") {
                    var web3 = new Web3(Wallet_Details.providers)
                    var balance = await checkOtherPlatformDetais1155(item, itemOwner, item.type, web3);
                    if (balance == 0) {
                        toast.warning("You won't buy at this moment please refresh you data", toasterOption);
                        setTimeout(() => {
                            window.location.href = "/"
                        }, 1000);
                        return false;
                    }
                    else {

                        Set_Item(item)
                        Set_TokenPrice(0);
                        Set_Owners(Owner)
                        Set_Item_Owner(itemOwner)
                        window.$('#cancel_order_modal').modal('show');
                    }
                }
                else {
                    window.$('#connect-wallet').modal('show');
                }
            }
        }),
    )
    return (
        <div className="modal fade primary_modal" id="cancel_order_modal" tabIndex="-1" role="dialog" aria-labelledby="cancel_order_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title" id="cancel_order_modalLabel">Cancel Order</h5>
                        <div className="change_price_img_div">
                        <Link to={"/info/" + Items.tokenOwner + '/' + Items.contractAddress + '/' + Items.tokenCounts}>
								<ImgVidAud
                                            data = "data"

								 file	=	{`${config.Back_URL}/compressedImage/${Items.tokenCreator}/${Items.image}`} //original image
								 thumb	=	{Items.thumb	?	`${config.Back_URL}/Thumb_compressedImage/${Items.tokenCreator}/${Items.thumb}`	:	`${config.Back_URL}/Thumb_nftImg/${Items.tokenCreator}/${Items.thumb_additionalImage}`} //thumb image
								 vidAud	=	{`${config.Back_URL}/compressedImage/${Items.tokenCreator}/${Items.additionalImage}`}
								 type	=	{Items.image}
								 class_name	=	{"img-fluid"}
								/>
								
							</Link>
                          </div>
                        <p className="text-gray font_we_600 font_12">You are about to delete Instant Sale for
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{Items.tokenName} </span>
                            for
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                {
                                    !isEmpty(Owners)
                                        && Owners.name != ""
                                        ? <span className="word_brak_text_inline_new" title={"Owner : " + Owners.name}>{Owners.name}</span>
                                        : <span className="word_brak_text_inline_new" title={"Owner : " + Item_Owner && Item_Owner.tokenOwner}>{String(Item_Owner && Item_Owner.tokenOwner).slice(0, 10).concat("...")}</span>

                                }
                            </span>
                        </p>

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={(FormSubmitLoading == 'processing' || FormSubmitLoading == 'done')}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body px-0 pt-0">
                        <form className="px-4 bid_form" >
                            <label htmlFor="bid">
                              {token_usd(Item_Owner.tokenPrice,1)}
                                {Item_Owner.CoinName}
                           
                                 </label>

                            <div className="text-center mt-3">
                                <Button
                                    type="button"
                                    className="primary_btn btn-block"
                                    onClick={() => (FormSubmitLoading == 'start' || FormSubmitLoading == 'try') ? FormSubmit() : ''}
                                    disabled={(FormSubmitLoading == 'processing' || FormSubmitLoading == 'done')}
                                >
                                    {FormSubmitLoading == 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    {FormSubmitLoading == 'processing' && 'In-Progress'}
                                    {FormSubmitLoading == 'done' && 'Done'}
                                    {FormSubmitLoading == 'start' && 'Start'}
                                    {FormSubmitLoading == 'try' && 'Try-Again'}
                                </Button>
                                <Button className="btn_outline_red btn-block"
                                    disabled={(FormSubmitLoading == 'processing')}
                                    data-dismiss="modal" aria-label="Close">Cancel</Button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

