import Web3 from "web3";
import config from '../../lib/config'
import SINGLE from '../../ABI/SINGLE.json'
import MULTIPLE from '../../ABI/MULTIPLE.json'
import DETH_ABI from '../../ABI/DETH_ABI.json'
import { useSelector } from 'react-redux';
import Web3Utils from 'web3-utils'
import { get_receipt } from "./contract_rpc";



export default function useContractProviderHook() {
    const Wallet_Details = useSelector(state => state.wallet_connect_context);
  
    const web3 = new Web3(Wallet_Details.providers)
    // contract connection
    const contrat_connection = async (abi_Array, address) => {
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                abi_Array, address
            );
            return contract_value;
        }
    }

    //Token Blanace calculation
    const Token_Balance_Calculation = async (wallet_Address, token_Address) => {
        try {
            //console.log("com here");
            const ConnectContract = await contrat_connection(DETH_ABI, token_Address)
            var bidAMt = await ConnectContract.methods.balanceOf(wallet_Address).call();
            if(bidAMt>0)
            return Number(web3.utils.fromWei(String(bidAMt)))
            else
            return 0
        }
        catch (e) {
            //console.log("com here",e);
            return 0
        }
    }

    // currency balance calculation
    const wallet_Balance_Calculation = async (wallet_Address) => {
        try {
            if (web3 != null) {
                if (localStorage.walletConnectType === 'wc' || localStorage.walletconnect != null) {
                    var bln = await web3.eth.getBalance(wallet_Address)
                    var wallet_Address_balance = Number(web3.utils.fromWei(bln))
                }
                else {
                    var wallet_Address_balance = await web3.eth.getBalance(wallet_Address)
                        .then(async (val) => {
                            wallet_Address_balance = web3.utils.fromWei(String(val));
                            return Number(wallet_Address_balance)
                        })
                }
                return wallet_Address_balance
            }
        }
        catch (e) {

            return 0
        }

    }

    // Token exsist in 721/ 1155
    const TokenExitsOrNotFunc = async(type,contractAddress,Token_Address,CoinName) => {
        try{
            const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, contractAddress)
        var tokenAddress = await ConnectContract.methods.getTokenAddress(CoinName).call()
        if(String(tokenAddress).toLowerCase() == String(Token_Address).toLowerCase())   {
            return true;
        } 
        else{
            //console.log("dsadsajdsa",tokenAddress)

            return false
        }
        }
          catch(e){
              //console.log("dsadsajdsa",e)
              return false
          }
      }

    // seller price calculation  
    var price_calculation =  (data,roy) => {
        try{
            var price =  data;
            var weii = price*1e6
            var ser_per = (weii/100000000)*((Wallet_Details.sellerfee/(10**18))*1000000)
            var roy_per = (weii/100000000)*((roy)*1000000)
            var mulWei = ((weii) - (ser_per+roy_per));
            var getVal = Number(mulWei)/1e6;
            return getVal

        }
        catch(e){
            return false
        }
    }

    // buyer price calcultion
    var buy_bid_price_calculation   =   async(newPrice,royality,decimal,pricedegit)=>{
        /**old one
        var serfee = (toMid)*((Wallet_Details.buyerfee/(10**18))*1000000)/100000000
        var totfee = serfee + toMid
        if(decimal == 18)
        {
                var tot2cont = web3.utils.toWei(String(Number((Number(totfee))/1000000)))
                var aprrove = web3.utils.toWei(String(((Number(totfee))/1000000)))
        }
        else{
                var tot2cont = web3.utils.toWei(String(Number((Number(totfee))/1000000)))
                var dec = 18 - (decimal);
                var aprrove = ((tot2cont)/10**dec)  

        }
        */
              
       
        // if(String(data.price).includes(".")){
        //     pricedegit = String(data.price).split(".")[1].length
        // }
        // var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
        // var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
        // var newPrice = item.type == 721 ? (price * (10 ** pricedegit)) : (quantity * (price * (10 ** pricedegit)) );
        if (String(Wallet_Details.buyerfee).includes('.'))
                var service = (Wallet_Details.buyerfee/1e17)/1000
            else
                var service = (Wallet_Details.buyerfee/1e18)/100

        var totfee=0
        if(pricedegit >= 1){
        //var toMid = newPrice
        var serfee = (newPrice * service) 
         totfee = ( newPrice + serfee ) / 10**pricedegit
      
        if(decimal == 18){
        //    var tot2cont = web3.utils.toWei(String(totfee / (10 ** pricedegit)))
           var aprrove = web3.utils.toWei(String(totfee))
        }
        else{
        //    var tot2cont = web3.utils.toWei(String(totfee / (10 ** pricedegit)))
           var aprrove = web3.utils.toWei(String(totfee))/(10 ** (18 - decimal))
        }
        }
        else{
           
            var serfee = (newPrice )*(service)
             totfee = newPrice + serfee
            if(decimal == 18){
                var aprrove = web3.utils.toWei(String(totfee))
            }
            else{
               var aprrove =   web3.utils.toWei(String(totfee))/(10 ** (18 - decimal))
                
            }
            //console.log("newPrice" , newPrice , decimal,pricedegit,serfee , aprrove,totfee,);
        return ({servicewithamt:Number(aprrove),send_value:Number(web3.utils.toWei(String(totfee))),pop_up_price:Number(totfee)})
    }

    }
  
    // get receipt for contract transaction hash value to for all write contract
   

    // listing
    const listing_721_1155 = async (type, token_Address, Accounts,TokenCount) => {
        try {
            if (web3 != null) {
           
                    const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Address)
                 
                       var contract_Method_Hash =  await ConnectContract
                       .methods
                       .listNft(TokenCount)
                       .send({
                           from: Accounts,
                           value: Wallet_Details.Listing_Fee
                       })
                       .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
                  
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        tokenCounts: TokenCount ? TokenCount : Web3Utils.hexToNumber(receipt.logs[0].topics[2])
                    }
                    return need_data
            }
        }

        catch (e) {
            return false
        }

    }
    // get contract symbol from 721 and 1155
    const contract_symbol = async (type, token_Address) => {
        try {
            if (web3 != null) {
                const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Address)
                const contract_Method_Hash = await ConnectContract.methods.symbol().call()
                return contract_Method_Hash
            }
        }
        catch (e) {
            return false
        }
    }

    // mint an token 
    const minting_721_1155 = async (type, token_Address, Token_Price, Token_Royalities, Token_Quantity, ipfs_metatag, Accounts, TokenCount, PutOnSale) => {
        try {
		var TokenPriceInStr = Token_Price	?	web3.utils.toWei(Token_Price)	:	0	;
            if (web3 != null) {
                    const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Address)
                 
                        var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .mint(
				                PutOnSale == true ? TokenCount : 0,
                                TokenPriceInStr,
                                Token_Quantity,
                                Token_Royalities,
                                ipfs_metatag
                                )
                            .send({ from: Accounts })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        tokenCounts: TokenCount ? TokenCount :  Web3Utils.hexToNumber(receipt.logs[1].topics[2])
                    }
                    return need_data
              

            }
        }

        catch (e) {
            return false
        }

    }

    // Signing metamask
    const Sign_contract_for_all = async (message, wallet_Address) => {
        try {
            if (web3 != null) {
                var contract_Method_Hash = web3.eth.personal.sign(message, wallet_Address);
                return contract_Method_Hash
            }
        }

        catch (e) {
            return false
        }

    }

    // burning Action 
    const burn_721_1155 = async (type, token_Address, Token_Counts,wallet_Address, Token_Quantity,  Accounts) => {
        try {
            if (web3 != null) {
               
                    const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Address)
                   if(type  ==   721 ){
                        var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .burnToken(Token_Counts,wallet_Address)
                            .send({ from: Accounts })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
                        }
                        else{
                            var contract_Method_Hash = await
                            ConnectContract
                                .methods
                                .burnToken(wallet_Address,Token_Counts,Token_Quantity)
                                .send({ from: Accounts })
                                .on('transactionHash', (transactionHash) => {
                                    return transactionHash
                                })
                            }

                    //console.log("hash value 2", contract_Method_Hash)
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
               

            }
        }

        catch (e) {
            return false
        }

    }

    // putonsale and lower price action
    const place_order_721_1155 = async (type, token_Address, Token_Counts, Token_Price,  Accounts) => {
        try {
            if (web3 != null) {
              
                    const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Address)    
                    var tknP    =   Token_Price ?web3.utils.toWei(String(Token_Price)):0          
                            var contract_Method_Hash = await
                            ConnectContract
                                .methods
                                .orderPlace(Token_Counts,tknP)
                                .send({ from: Accounts })
                                .on('transactionHash', (transactionHash) => {
                                    return transactionHash
                                })
                                
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
               

            }
        }

        catch (e) {
            return false
        }

    }

    // cancel sale order
    const cancel_order_721_1155 = async (type, token_Address, Token_Counts,   Accounts) => {
        try {
            if (web3 != null) {
              
                    const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Address)
                            var contract_Method_Hash = await
                            ConnectContract
                                .methods
                                .cancelOrder(Token_Counts)
                                .send({ from: Accounts })
                                .on('transactionHash', (transactionHash) => {
                                    return transactionHash
                                })
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
            }
        }

        catch (e) {
            return false
        }

    }

    // buy now
    const buy_721_1155 = async (type, token_Address, Token_Counts,  CoinName, tokenOwner, Amount ,send_value ,NoOfToken,Accounts) => {
        //console.log("(type, token_Address, Token_Counts, Accounts, CoinName, tokenOwner, Amount ,send_value ,NoOfToken)",type, token_Address, Token_Counts, Accounts, CoinName, tokenOwner, Amount ,send_value ,NoOfToken)
        try {
            if (web3 != null) {
             
                    const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Address)
                   if(type == 721) {
                       if(CoinName == config.currencySymbol){
                             var contract_Method_Hash = await
                                                        ConnectContract
                                                            .methods
                                                            .saleToken(tokenOwner,Token_Counts,Amount)
                                                            .send({ 
                                                                from    : Accounts ,    
                                                                value    : send_value    })
                                                            .on('transactionHash', (transactionHash) => {
                                                                return transactionHash
                                                            })
                                                        }
                                                        else{
                                                            var contract_Method_Hash = await
                                                            ConnectContract
                                                            .methods
                                                            .saleWithToken(CoinName,tokenOwner,Token_Counts,Amount)
                                                            .send({ from    :    Accounts })
                                                            .on('transactionHash', (transactionHash) => {
                                                                return transactionHash
                                                            })}}
                                                            else{
                                                                if(CoinName == config.currencySymbol){
                              var contract_Method_Hash = await
                                                     ConnectContract
                                                         .methods
                                                         .saleToken(tokenOwner,Token_Counts,Amount,NoOfToken)
                                                         .send({ 
                                                             from    : Accounts ,    
                                                             value    : send_value    })
                                                         .on('transactionHash', (transactionHash) => {
                                                             return transactionHash
                                                         })
                                                     }
                         else{
                             var contract_Method_Hash = await
                                                         ConnectContract
                                                             .methods
                                                             .saleWithToken(tokenOwner,Token_Counts,Amount,NoOfToken,CoinName)
                                                             .send({ from    :    Accounts })
                                                             .on('transactionHash', (transactionHash) => {
                                                                 return transactionHash
                                                             })}
                    }

                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
               

            }
        }

        catch (e) {
            //console.log("all data ",e)
            return false
        }

    }

    //  aprove for both sale with token and bid now
    const approve_721_1155 = async (token_address, token_Contract_Address, Amount, Accounts) => {
        try {
            if (web3 != null) {
              
                const ConnectContract = await contrat_connection(DETH_ABI, token_address)
                var contract_Method_Hash = await
                ConnectContract
                .methods
                .approve(token_Contract_Address,Amount)
                .send({ from    :    Accounts })
                .on('transactionHash', (transactionHash) => {
                    return transactionHash
                    
                })
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
               

            }
        }

        catch (e) {
            //console.log("check ",e);
            return false
        }

    }

    // biding user allowance
    const allowance_721_1155 = async (token_Contract_Address,wallet_Address,token_Address) => {
        try {
            if (web3 != null) {
             
                   const ConnectContract = await contrat_connection(DETH_ABI, token_Address)
                             var contract_Method_Hash = await
                                                        ConnectContract
                                                            .methods
                                                            .allowance(wallet_Address,token_Contract_Address)
                                                            .call()
                   
                    return contract_Method_Hash

            }
        }

        catch (e) {
            return false
        }

    }

    // Accept bid
    const accept_721_1155 = async (type,token_Contract_Address,CoinName,Bid_Address,Amount,token_counts,NoOfToken,Account) => {
        try {
            if (web3 != null) {
                const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Contract_Address)
                if(type  ==  721)
                { 
                   var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .acceptBId(CoinName, Bid_Address, Amount, token_counts)
                            .send({ from: Account })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })}
                            else{
                                var contract_Method_Hash = await
                                ConnectContract
                                .methods
                                .acceptBId(CoinName, Bid_Address, Amount, token_counts,NoOfToken)
                                .send({ from: Account })
                                .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })}
                    
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
                }}
                catch (e) {
                    //console.log("ewew",e)
                    return false
                }

            }
    
    // Transfer 
    const transfer_721_1155 = async (type,token_Contract_Address,UserAccountAddr,New_TokenOwners,tokenCounts,NoOFItems,Account) => {
        try {
            if (web3 != null) {
                const ConnectContract = await contrat_connection(type == 721 ? SINGLE : MULTIPLE, token_Contract_Address)
                if(type  ==  721)
                { 
                    var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .safeTransferFrom(
								UserAccountAddr,
								New_TokenOwners,
								tokenCounts,
							)
                            .send({ from: Account })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })}
                    else{
                        var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .safeTransferFrom(
								UserAccountAddr,
								New_TokenOwners,
								tokenCounts,
								NoOFItems,
								'0x'
							)
                            .send({ from: Account })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })}
                    
                    //console.log("hash value 2", contract_Method_Hash)
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                    }
                    return need_data
                }}
                catch (e) {
                    return false
                }

            }
            
   
        return {    Token_Balance_Calculation, 
                    wallet_Balance_Calculation,
                    minting_721_1155,
                    Sign_contract_for_all, 
                    contract_symbol, 
                    burn_721_1155,
                    place_order_721_1155,
                    price_calculation,
                    cancel_order_721_1155,
                    buy_bid_price_calculation,
                    buy_721_1155,
                    approve_721_1155,
                    allowance_721_1155,
                    accept_721_1155,
                    TokenExitsOrNotFunc,
                    listing_721_1155,
                    transfer_721_1155
                };
    }



