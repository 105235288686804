
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Avatars from "views/Avatar";
import moment from 'moment'
import isEmpty from "lib/isEmpty";
import config from '../../lib/config';
import ImgVidAud from "./imgVidAud";
export default function ActivityCard(props) {
  var history = useHistory();
  var {
    item,
    type, data ,myadd
  } = props;

  return (

    type == 'activity' ?
     (item.action === "follow" || item.action === "unfollow") ? 
     item.to_user.curraddress === myadd && 
     <div className="col-12 col-md-6 col-xl-4 mb-4"  >

        <div className="card my-0 h-100 acti_card">
          <div className="card-body px-3">
            <div className="media follow_media icon_img">
              <div className="icon-img-div">
                <div className="">
                </div>
                <div className="img_media_new  mr-3">
                  {
                      ( item.fromField
                        && <div onClick={() => history.push(item.fromField.customurl != "" ? `/${item.fromField.customurl}` : `/user/${item.fromField.curraddress}`)}>
                          {item.fromField.image
                            ? <img alt="User" loading="lazy" className="img-fluid" src={`${config.Back_URL}/images/${item.fromField.curraddress}/${item.fromField.image}`} />

                            :
                            <Avatars item="img-fluid" />}
                        </div>)
                    

                  }


                </div>
              </div>
              <div className="media-body flex_body">
                <div>
                  {<>
                      
                       
                        {
                        <>
                       
                        <div onClick={() => history.push(`/user/${item.fromField.curraddress}`)}>
                        <p className="mt-0 media_num mt-0">
                        <span className="kslp_color_add_2 mr-1 pr-1">You</span>  following  </p>
                          <p className="my-0 media_text kslp_color_add_2" title={(item.fromField && item.fromField.name != "") ? item.fromField.name : item.fromField.curraddress}>{(item.fromField && item.fromField.name != "") ? item.fromField.name : item.fromField.curraddress && item.fromField.curraddress != "" && (String(item.fromField.curraddress).slice(0, 8).concat('...'))}</p></div>
                        
                        </>
                   
                        }
                       
                        </>
                    
                  }

                </div>

              </div>
            </div>
            <div className="ml-2">
              <p className="mt-0 media_num mb-0 dur_text kslp_color_add">		{moment(item.created).fromNow()}
              </p>
            </div>

          </div>
        </div></div>
        :
        <div className="col-12 col-md-6 col-xl-4 mb-4"  >

        <div className="card my-0 h-100 acti_card">
          <div className="card-body px-3">
            <div className="media follow_media icon_img">
              <div className="icon-img-div">
                <div className="">
                </div>
                <div className="img_media_new  mr-3">
                <Link to={"/info/" + item.tokenOwner + '/' + item.fromField.curraddress + '/' + item.tokenCounts}>
								<ImgVidAud
                                            data = "activity"

								 file	=	{`${config.Back_URL}/compressedImage/${item.tokenCreator}/${item.image}`} //original image
								 thumb	=	{item.thumb	?	`${config.Back_URL}/Thumb_compressedImage/${item.tokenCreator}/${item.thumb}`	:	`${config.Back_URL}/Thumb_nftImg/${item.tokenCreator}/${item.thumb_additionalImage}`} //thumb image
								 vidAud	=	{`${config.Back_URL}/compressedImage/${item.tokenCreator}/${item.additionalImage}`}
								 type	=	{item.image}
								 class_name	=	{"img-fluid"}
								/>
								
							</Link>
                 
                </div>
              </div>
              <div className="media-body flex_body">
                <div>
                  { (item.tokenName
                      &&
                      <> <div onClick={() => history.push(`/info/${item.to_user.curraddress}/${item.contractAddress}/${item.tokenCounts}`)}>
                        <p className="my-0 media_text kslp_color_add_2" title={item.tokenName}> {item.tokenName}</p></div>
                        <p className="mt-0 media_num">{item.activity}
                        <span className="my-0 mt-0 media_text ml-1 kslp_color_add_2" title={(item.fromField && item.fromField.name != "") ? item.fromField.name : (item.fromField.curraddress)}>
                            {(item.fromField && item.fromField.name != "") ? item.fromField.name : item.fromField.curraddress != "" && String(item.fromField.curraddress).slice(0, 8).concat('...')}
                          </span></p>

                          {/* <span className="my-0 mt-0 media_text ml-1" title={(item.to_user && item.to_user.name != "") ? item.to_user.name : (item.to_user.curraddress)}>
                            {(item.to_user && item.to_user.name != "") ? item.to_user.name : item.to_user.curraddress != "" && String(item.to_user.curraddress).slice(0, 8).concat('...')}
                          </span></p> */}


                        <div >
                          <p className="my-0 media_num mt-0">
                            {item.amount ? `For ${item.amount} ${item.currencySymbol} for each` : null} </p>
                          {(item.action == "mint" || item.action  === "changeprice"  || item.action  === "cancelbid") ?  null: <p className="my-0 mt-0 media_num kslp_color_add_2" 
                          title={(item.to_user && item.to_user.name != "") ? item.to_user.name : (item.to_user.curraddress)}>from <span className="media_text">{(item.to_user && item.to_user.name != "") ? item.to_user.name : item.to_user.curraddress != "" && String(item.to_user.curraddress).slice(0, 8).concat('...')}</span></p>
                          }</div>
                      </>
                    )
                  }

                </div>

              </div>
            </div>
            <div className="ml-2">
              <p className="mt-0 media_num mb-0 dur_text kslp_color_add">		{moment(item.created).fromNow()}
              </p>
            </div>

          </div>
        </div></div>
      :
      !isEmpty(item.user) &&
      <div className="col-12 col-md-6 col-xl-4 mb-4"  >

        <div className="card my-0">
        
            <div className="card-body">
              <div className="media follow_media">
                <div className="img_media_new  mr-3">


                  <a href={(item.user.customurl) ? config.Front_URL + '/' + item.user.customurl : config.Front_URL + '/user/' + item.user.curraddress} target="_blank">
                    {item.user.image ? <img src={`${config.Back_URL}/images/${item.user._id}/${item.user.image}`} alt="User" className="img-fluid" onClick={item.user.customurl ? `/${item.user.customurl}` : `/user/${item.user.curraddress}`} />
                      : <Avatars item="img-fluid" />
                    }</a>


                </div>
                <div className="media-body flex_body"
                >
                  <div>
                    {
                      type == "follower" &&
                      <a href={(item.user.customurl) ? config.Front_URL + '/' + item.user.customurl : config.Front_URL + '/user/' + item.user.curraddress}>
                        <p className="my-0 media_text" title={'User :' + (item.user.name != "" ? item.user.name : item.user.curraddress)}>{(item.user.name != "" ? item.user.name : item.user.curraddress != "" && String(item.user.curraddress).slice(0, 10).concat("..."))}</p>
                      </a>}
                    <p className="mt-0 media_num font_14 mb-0">{data}</p>
                    {
                      type == "follow" &&
                      <a href={(item.user.customurl) ? config.Front_URL + '/' + item.user.customurl : config.Front_URL + '/user/' + item.user.curraddress}>
                        <p className="my-0 media_text" title={'User :' + (item.user.name != "" ? item.user.name : item.user.curraddress)}>{(item.user.name != "" ? item.user.name : item.user.curraddress != "" && String(item.user.curraddress).slice(0, 10).concat("..."))}</p>
                      </a>}

                  </div>
                  <div className="ml-2">
            {item.timestamp  ?   <p className="mt-0 media_num mb-0 dur_text kslp_color_add_2">		{moment(item.timestamp  &&  item.timestamp).fromNow()}
              </p>
              :
              <p className="mt-0 media_num mb-0 dur_text kslp_color_add_2">		{
               moment(new Date( parseInt( (item._id.toString().substring(0,8)), 16 ) * 1000 )).fromNow()
                }
              </p>
              }
            </div>
                </div>
              </div>

            </div>
        </div>
      </div>

  )
}
